<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="组局类型名称">
        <el-input style="width: 250px" size="mini" v-model="formData.name" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="背景图">
        <UploadImgs :background_images.sync="formData.bgImage"></UploadImgs>
      </el-form-item>
      <el-form-item label="icon">
        <UploadImg :url.sync="formData.icon"></UploadImg>
      </el-form-item>
      <el-form-item label="类型">
        <el-radio-group v-model="formData.type">
          <el-radio :label="0">线下游戏组局</el-radio>
          <el-radio :label="1">线上游戏组局</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="每小时金额">
        <el-input-number size="mini" style="width: 250px" v-model="formData.everyHouseMoney" :step="1" step-strictly :min="0" placeholder="请输入"></el-input-number>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number size="mini" style="width: 250px" v-model="formData.sort" :step="1" step-strictly :min="0" placeholder="请输入"></el-input-number>
      </el-form-item>
      <el-form-item label="">
        <el-button type="" size="small" @click="$router.back()">取 消</el-button>
        <el-button type="primary" size="small" @click="save">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addAPI, editAPI, getDetailAPI } from './api'
import UploadImgs from '@/components/qiniu-uploader/uploader-imgs.vue'
import UploadImg from '@/components/qiniu-uploader/uploader-img.vue'
export default {
  name: 'Update',
  components: { UploadImgs, UploadImg },
  data() {
    return {
      formData: {
        name: '',
        sort: 0,
        bgImage: [],
        icon: '',
        type: 0,
        everyHouseMoney: ''
      },
      bgList: []
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },

  methods: {
    save() {
      this.formData.bgImage = this.formData.bgImage.map((item) => item.url)
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
      this.formData.bgImage = this.formData.bgImage.map((item) => {
        return {
          url: item
        }
      })
    }
  }
}
</script>
